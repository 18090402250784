<template>
  <div class="content-card">
    <div class="content-card__subtitle">
      <h2 class="tag tag-open">Перерыв</h2>
    </div>
    <div class="form-group">
      <label for="nameStartList">Название перерыва</label>
      <input type="text" id="nameStartList" class="input" v-model="nameBreak" placeholder="Введите название соревнований" required />
      <!-- Поле ввода времени на 1 выступление -->
    </div>
    <div class="form-group__row">
    <div class="form-group__row-item">
      <label for="timePerPerformanceWithSpinner">Время на перерыв в минутах</label>
      <input type="number" id="timePerPerformanceWithSpinner" class="input" v-model="timeBreak" min="0" />
    </div>
    <div class="form-group__row-item">
      <label for="timePerPerformanceWithSpinner">На какое место потока его поставить</label>
      <input type="number" id="timePerPerformanceWithSpinner" class="input" v-model="position" min="0" />
    </div>
    </div>
    <div class="form-group-btn">
      <button class="mandated__tag active" @click="createBreak">Создать перерыв</button>
    </div>

  </div>
</template>
<script >
  import { actionTypesStartList } from '@/store/modules/createStartList'

  export default {
    components: {  },
    name:'AppCreateFlowBreak',
    data() {
      return {
        nameBreak: '',
        timeBreak: '',
        orderPerformance: '',
        position: '',

      };
    },
    props: {
      about_start_list: {
        type: Object,
      }
    },
    methods: {

      createBreak() {
        var breakData = {
          competitionId:   this.$route.params.id,
          startDateAndTime:  this.about_start_list.startDateAndTime,
          name: this.nameBreak,
          duration: this.timeBreak,
          position: this.position,
        }
        this.$store
            .dispatch(actionTypesStartList.addBreakFlowInProtocol, breakData)
            .then((res) => {
                  breakData = {}
                  this.nameBreak =  '',
                  this.timeBreak= '',
                  this.orderPerformance= '',
                  this.position= '',
              console.log(res)
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
    },

  }
</script>
