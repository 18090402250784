<template>
  <div class="main-content start-protocol-page">
    <!-- Заголовок страницы -->
    <Header message="Создание стартового протокола" />

    <!-- Карточка для базовых данных соревнования -->
    <div class="form-card">
      <form>
        <div class="form-group">
          <label for="nameStartList">Название соревнований</label>
          <input type="text" id="nameStartList" class="input" v-model="form.nameStartList" placeholder="Введите название соревнований" required />
        </div>

        <div class="form-group">
          <label for="startDateAndTime">Дата и время начала</label>
          <input type="datetime-local" id="startDateAndTime" class="input" v-model="form.startDateAndTime" required />
        </div>

        <div class="form-group">
          <label for="idRounding">Округление времени</label>
          <div class="checkbox-group">
            <input type="checkbox" id="idRounding" class="checkbox-input" v-model="form.roundingTime" />
            <label for="idRounding" class="checkbox-label">Включить округление</label>
          </div>
        </div>
        <div class="form-group__row">
          <div class="form-group__row-item">
          <button type="button" class="mandated__tag active" @click="getPerformance">Показать стартовый</button>
          </div>
          <div class="form-group__row-item">
        <button
            type="button"
            @click="createStartProtocol"
            class="mandated__tag active"
        >
          Скачать стартовый протокол
        </button>
          </div>
        </div>
      </form>
    </div>

    <!-- Выбор групповых или обычных номинаций -->
    <div class="nomination-toggle">
      <label>
        <input type="radio" v-model="isGroupNomination" value="INDIVIDUAL"  />
        Индивидуальные номинации
      </label>
      <label>
        <input type="radio" v-model="isGroupNomination" value="GROUP" />
        Групповые номинации
      </label>
    </div>

    <!-- Tab меню для выбора блока -->
    <div class="tab-menu">
<!--      <button :class="['tab-button', { active: activeTab === 'noSpinner' }]" @click="activeTab = 'noSpinner'">Без вертушки</button>-->
      <button :class="['tab-button', { active: activeTab === 'withSpinner' }]" @click="activeTab = 'withSpinner'">Создание потоков</button>
      <button :class="['tab-button', { active: activeTab === 'break' }]" @click="activeTab = 'break'">Перерыв</button>
    </div>

    <!-- Блок "Без вертушки" -->
    <AppCreateFlowNoSpinner
        v-if="activeTab === 'noSpinner'"
        :listNomination="currentNomination"
        :about_start_list="this.form"
        :isGroupNomination="this.isGroupNomination"
    />

    <!-- Блок "С вертушкой" -->
    <AppCreateFlowWithSpinner
        v-if="activeTab === 'withSpinner'"
        :listNomination="currentNomination"
        :about_start_list="this.form"
        :isGroupNomination="this.isGroupNomination"
    />

    <!-- Блок "Перерыв" -->
    <AppCreateFlowBreak v-if="activeTab === 'break'"  :about_start_list="this.form" />
    <!-- Карточки представления потоков -->
   <template v-if="listPerformance">
    <CardPerformance  v-for="(item, index) in listPerformance" :item_flow="item"  :idx="index" :key="index" :about_start_list="form" />
   </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Header from '@/components/Header.vue';
  import { actionTypesStartList } from '@/store/modules/createStartList';
  import AppCreateFlowNoSpinner from '@/components/Startlist/CreateFlowNoSpinner.vue';
  import AppCreateFlowWithSpinner from '@/components/Startlist/CreateFlockViewSpinner.vue';
  import AppCreateFlowBreak from '@/components/Startlist/CreateFlowBreak.vue';
  import CardPerformance from '@/components/Startlist/CardPerformance.vue';
  import axios from 'axios'

  export default {
    components: {
      CardPerformance,
      AppCreateFlowBreak,
      AppCreateFlowWithSpinner,
      AppCreateFlowNoSpinner,
      Header,
    },
    name: "NewCreateStartList",
    data() {
      return {
        form: {
          nameStartList: 'Чемпионат и Первенство Республики Алтай по художественной гимнастике "Огни Алтая',
          startDateAndTime: "2024-11-21T10:00",
          roundingTime: true,
          numberOfAthletesInStream: 1,

        },
        activeTab: 'withSpinner',
        isGroupNomination: 'GROUP', // Управление между обычными и групповыми номинациями
      };
    },
    computed: {
      ...mapState({
        listNomination: (state) => state.createStartList.listNomination,
        listGroupNomination: (state) => state.createStartList.listGroupNomination,
        listPerformance: (state) => state.createStartList.listPerformance,
      }),
      // Выбор текущей номинации в зависимости от выбранного режима
      currentNomination() {
        return this.isGroupNomination === 'GROUP' ? this.listGroupNomination : this.listNomination;
      },
    },
    methods: {
      getPerformance() {
        this.$store
            .dispatch(actionTypesStartList.getStartListPerformance, {
              competitionId: this.$route.params.id,
              startDateTime: this.form.startDateAndTime,
            })
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      createStartProtocol() {
        // console.log({
        //   listBreak: this.listBreak,
        //   startDateAndTime: this.startDateAndTime,
        //   resultStartList: this.resultStartList,
        //   timeForPerformans: this.timeForPerformans,
        //   nameStartList: this.nameStartList,
        // });
        this.endData = {
          competitionId:  this.$route.params.id,
          startDateAndTime: this.form.startDateAndTime,
        };
        axios
            .post(`/export/protocol`, this.endData, {
              responseType: "blob",
            })
            .then((response) => {
              console.log(response);
              const url = URL.createObjectURL(
                  new Blob([response.data], {
                    type: "application/vnd.ms-excel",
                  })
              );
              var fileName = `Протокол.xlsx`;
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
            });
      },
    },
    mounted() {
      // Получение информации о номинациях
      this.$store
          .dispatch(actionTypesStartList.getNominationsList, this.$route.params.id)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });

      this.$store
          .dispatch(actionTypesStartList.getNominationGroupList, this.$route.params.id)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
    },
  };
</script>

<style scoped>
.main-content {
  padding: 20px;
}

.nomination-toggle {
  margin: 20px 0;
  display: flex;
  gap: 20px;
}

.nomination-toggle label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
}
</style>
