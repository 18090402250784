<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask" :style="{ 'z-index': 900 }">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <form @submit.prevent="safeInfoGroup"> <!-- Убедитесь, что метод вызывается только при нажатии кнопки -->
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Добавление групп</p>
              </div>
              <ValidationErrors
                  v-if="validationErrorsGroup"
                  :validation-errors="validationErrorsGroup.message"
              />
              <div class="modal-scroll-max">

                <div class="input-wrap__column">
                  <div class="input-wrap__label-with-input-column">
                    <div class="input-wrap__label">
                      <p class="text-label">Название группы:</p>
                    </div>
                    <input
                        type="text"
                        class="input-light-text"
                        :class="{
                      'error-border-input': isSubmitted && $v.name.$error,
                    }"
                        placeholder="Заполните название группы"
                        name="name"
                        v-model="name"
                    />
                    <img
                        src="~@/assets/svg/Error.svg"
                        alt="error"
                        v-if="isSubmitted && $v.name.$error"
                        class="wrapValidationInput__error"
                    />
                  </div>
                </div>

                <div class="modal-body__table">
                  <div
                      v-if="isSubmitted && !$v.checkedCoachID.required"
                      class="validation"
                  >
                    <p class="validation__text">*Назначьте тренера</p>
                  </div>
                  <div class="input-wrap__label">
                    <p class="text-label">Тренеры:</p>
                  </div>
                  <table-coach
                      ref="check"
                      @addCoach="addCoach"
                      @checkedCoach="checkedCoach"
                  />
                </div>
                <!-- Блок для отображения ошибки длины массива -->
                <div v-if="isSubmitted && $v.selectPeople.$error && !$v.selectPeople.minLength"  class="message-about-empty empty">
                  <p class="message-about-empty__text"> Добавлено менее 5 спортсменов в группу!</p>
                </div>

                <div class="input-wrap">
                  <div class="input-wrap__label">
                    <p class="text-label">Состав группы</p>
                  </div>

                  <div
                      class="group-list-in-modal"
                      v-if="this.selectPeople.length !== 0"
                  >
                    <table class="custom-table">
                      <thead>
                      <tr>
                        <th class="empty_th"></th>
                        <th class="th__name">Ф.И.О.</th>
                        <th class="th__years">Год р.</th>
                        <th class="th__section">Разряд</th>
                        <th class="th__trainer">Тренеры</th>
                      </tr>
                      </thead>
                      <tbody>
                      <item-table-sportsmen
                          v-for="(sportsmen, i) in this.selectPeople"
                          :key="i"
                          :item_sportsmen="sportsmen"
                          @editSportsmen="editSportsmen"
                          @deleteSportsmen="deleteSportsmen"
                      />

                      </tbody>
                    </table>
                  </div>
                  <AppAddPeopleSelect
                      v-model="selectPeople"
                  :selectIndCateg="application_category"
                  :options="allSportsmens"
                  :class="{ 'error-border-input': isSubmitted && $v.selectPeople.$error }"
                  :windowWidth="isMobile"
                  />
                  <div @click="addSportsmen" class="add-person-button-div">
                    <div class="add-person-div">
                      <img
                          src="../../assets/svg/plus.svg"
                          class="add-person-div__img"
                          alt="cross"
                      />
                    </div>

                    <div class="add-person-div__text">Создать нового спортсмена</div>
                  </div>
                </div>

                <div class="input-wrap__row">
                  <div class="input-wrap__label-with-input-row">
                    <div class="input-wrap__label">
                      <p class="text-label">Область/регион:</p>
                    </div>
                    <AppRegionSelect @regionSelected="safeRegion" :default="infoUser.region"  :class="{
                    'error-border-input': isSubmitted && $v.region.$error,
                  }"/>
                  </div>
                  <div class="input-wrap__label-with-input-row" v-if="this.region" ref="cityBlock">
                    <div class="input-wrap__label">
                      <p class="text-label">Город:</p>
                    </div>
                    <AppCitySelect :idRegion="this.region.id"  @citySelected="safeCity" :default="infoUser.city" :class="{
                    'error-border-input': isSubmitted && $v.city.$error,
                  }" />
                  </div>
                </div>

                <div
                    class="message-about-empty empty"
                    v-if="isSubmitted && this.$v.$invalid"
                >
                  <p class="message-about-empty__text">Заполните все данные!</p>
                </div>
              </div>
              <div class="modal-footer">
                <div class="modal-footer__button">
                  <button class="mandated__tag dark">Сохранить</button>
                </div>
                <div class="modal-footer__button">
                  <button
                      type="button"
                      class="mandated__tag button-cancel"
                      @click="$emit('close')"
                  >
                    Отменить
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { actionTypes } from "@/store/modules/registration";
  import { actionTypesEntered } from "@/store/modules/enteredData";
  import { mapState } from "vuex";
  import TableCoach from "../TableInModal/TableCoach.vue";
  import { required, minLength } from "vuelidate/lib/validators";
  import ItemTableSportsmen from "../Table/ItemTableSportsmen.vue";
  import ValidationErrors from '@/components/ValidationErrors.vue';
  import AppRegionSelect from '@/components/customSelect/RegionSelect.vue';
  import AppCitySelect from '@/components/customSelect/CitySelect.vue';
  import AppAddPeopleSelect from '@/components/customSelect/AddPeopleSelect.vue';
  import { actionTypesMandated } from '@/store/modules/mandated';

  export default {
    name: "AppModalAddGroup",
    data() {
      return {
        name: "",
        region: "",
        city: "",
        rank: "",
        checkedCoachID: [],
        selectPeople: [],
        sportsmenId: [],
        defaultRegion: "Выберите регион",
        defaultCity: "Выберите город",
        defaultSportsmen: "Добавление спортсмена",
        isSubmitted: false,
        windowWidth: window.innerWidth,
      };
    },
    props: {
      application_category: {
        type: Object,
        default: () => {}
      },
      category: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    validations: {
      name: {
        required,
      },
      region: {
        required,
      },
      city: {
        required,
      },
      checkedCoachID: {
        required,
      },
      selectPeople: {
        required,
        minLength: minLength(5)
      },
    },
    components: {
      AppAddPeopleSelect,
      AppCitySelect,
      AppRegionSelect,
      ValidationErrors,
      TableCoach,
      ItemTableSportsmen,
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.registration.isLoading,
        infoUser: (state) => state.auth.infoUser,
        selectRank: (state) => state.registration.selectRank,
        allSportsmens: (state) => state.enteredData.allSportsmens,
        validationErrorsGroup: (state) => state.enteredData.validationErrorsGroup
      }),
      isMobile() {
        return this.windowWidth <= 1024;
      },
    },
    methods: {
      checkedCoach(item) {
        this.checkedCoachID = item;
      },
      editSportsmen(item) {
        this.$emit("editSportsmen", item);
      },
      safePeople(item) {
        // Собираем выбранных спортсменов, не отправляя данные на сервер
        console.log(item, 'item');
        this.selectPeople = item.allInformation;
      },
      deleteSportsmen(item) {
        console.log(item, 'item modal add group')
        this.selectPeople = this.selectPeople.filter(n => n.id !== item.id);
        // this.selectPeople.allInformation = this.selectPeople.allInformation.filter(n => n.id !== item.id);
      },
      async safeInfoGroup() {
        // Собираем ID выбранных спортсменов
        console.log('Я')
        this.sportsmenId = this.selectPeople.map(item => item.id);

        this.isSubmitted = true;
        this.$v.$touch();

        if (this.$v.$invalid) {
          console.log("Данные не заполнены");
          return;
        }

        // Собираем данные для группы
        const groupData = {
          name: this.name,
          region: this.region.name || this.region,
          city: this.city,
          coachId: this.checkedCoachID,
          sportsmenId: this.sportsmenId,
        };

        console.log( this.application_category,' this.application_category')
        try {
          // Условие 1: Если есть объект category, делаем запрос на actionTypesMandated.addGroupToCategory
          if (this.category && this.category.id) {
            groupData.idCategory = this.category.id;
            await this.$store.dispatch(actionTypesMandated.addGroupToCategory, groupData);
          }
          // Условие 2: Если есть объект application_category, делаем запрос на actionTypesEntered.registrGroupWithCateg
          else if (this.application_category && this.application_category.id) {
            groupData.idCategory = this.application_category.id;
            await this.$store.dispatch(actionTypes.registrGroupWithCateg, groupData)
                .then(() => {
                  // this.application_category = null;
                });
          }
          // Условие 3: Если нет ни category, ни application_category, делаем запрос на actionTypesEntered.registrGroup
          else {
            await this.$store.dispatch(actionTypesEntered.registrGroup, groupData);
          }

          // Очищаем форму после успешного добавления
          this.clearForm();
          this.$emit("close");
        } catch (er) {
          console.log("Ошибка", er);
        }
      },
      clearForm() {
        // Метод для очистки формы после успешной отправки
        this.name = "";
        this.region = "";
        this.city = "";
        this.checkedCoachID = [];
        this.selectPeople = [];
        this.sportsmenId = [];
        this.$v.$reset();
        this.isSubmitted = false;
      },
      addCoach() {
        this.$emit("addCoach");
      },
      addSportsmen() {
        this.$emit("addSportsmen");
      },
      safeCity(item) {
        console.log(item, "city");
        this.city = item;
      },
      safeRegion(item) {
        console.log(item, "region");
        this.region = item;
        this.scrollToCityBlock();
      },
      scrollToCityBlock() {
        this.$nextTick(() => {
          if (this.$refs.cityBlock) {
            this.$refs.cityBlock.scrollIntoView({
              behavior: 'smooth', // Плавный скролл
              block: 'start',
            });
          }
        });
      },
    },
    mounted() {
      if (this.infoUser) {
        this.city = this.infoUser.city || '';
        this.region = this.infoUser.region || '';
      }
      // Получение всех спортсменов
      this.$store
          .dispatch(actionTypesEntered.getSportsmens)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
      // Получение всех тренеров
      this.$store
          .dispatch(actionTypesEntered.getCoaches)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
      // Получение всех разрядов
      this.$store
          .dispatch(actionTypes.getRank)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
    },
  };
</script>

<style scoped>
/* Добавьте ваши стили здесь */
</style>
