<template>
  <tbody style="border-bottom: 2px solid rgb(205 205 200);">
  <template>
    <tr v-for="(sportsman, j) in item_group.sportsman" :key="j" >
      <td class="edit__btn"  v-if="j === 0"  :rowspan="item_group.sportsman.length">
        <img
            class="icon__edit"
            src="../../assets/svg/pain.svg"
            alt="Edit"
            @click="editGroup"
        />
      </td>
      <!-- Первая ячейка только для первого спортсмена в группе -->
      <td
          v-if="j === 0"
          class="tb__checkbox"
          :rowspan="item_group.sportsman.length"
      >
        {{ idx + 1 }}
      </td>
      <!-- Первая ячейка только для первого спортсмена в группе -->
      <td
          v-if="j === 0"
          class="tb__checkbox"
          :rowspan="item_group.sportsman.length"
      >
        {{ item_group.name }}
      </td>
      <!-- Ячейка со спортсменом -->
      <td class="tb__name br10">{{ sportsman.fio }}</td>
      <!-- Ячейки с остальными данными группы, только для первого спортсмена -->
      <td
          v-if="j === 0"
          class="tb__name"
          :rowspan="item_group.sportsman.length"
      >
        {{ item_group.city }}, {{ item_group.club }}
      </td>

      <td
          v-if="j === 0"
          class="tb__name"
          :rowspan="item_group.sportsman.length"
      >
        {{ item_group.coachNames }}
      </td>
      <td class="tb__checkbox"   v-if="j === 0"   :rowspan="item_group.sportsman.length">
        <AppCategorySelect :all-category="listNominationGroup" @categorySelected="safeNewCategory"  :default="item_categ.name" :key="item_group.regGroupId"/>
      </td>
      <td class=""  v-if="j === 0"   :rowspan="item_group.sportsman.length">
        <div class="tb__tag-success tag-active" @click="changeStatusPerformance()" v-if="item_group.status === 'PRESENT'">
          <div class="loader-btn" v-if="isLoadingStatusGroup"></div>
          <p class="tb__tag-success-text" v-else >Выступает</p>

        </div>
        <div class="tb__tag-success tag-not-active" @click="changeStatusPerformance()" v-else>
          <div class="loader-btn"  v-if="isLoadingStatusGroup" ></div>
          <p class="tb__tag-success-text" v-else>Не выступает</p>
        </div>
      </td>
    </tr>
  </template>
  </tbody>
</template>
<script>
  import AppCategorySelect from '@/components/customSelect/CategorySelect.vue'
  import { actionTypesMandated } from '@/store/modules/mandated'
  import { mapState } from 'vuex'

  export default {
    name: 'AppItemGroupInMandated',
    components: { AppCategorySelect },

    data() {
      return {
      };
    },
    props: {
      item_group: {
        type: Object,
        default() {
          return {};
        },
      },
      item_categ: {
        type: Object,
        default() {
          return {};
        },
      },
      idx: {
        type: Number,
        default() {
          return {};
        },
      },
      safeGroupCategories: {
        type: Object,
        default() {
          return {};
        },
      },
      listNominationGroup: {
        type: Array,
        default() {
          return {};
        },
      }
    },
    computed: {
      ...mapState({
        isLoadingStatusGroup: (state) => state.mandated.isLoadingStatusGroup,
      }),
    },

    methods: {
      editGroup() {
        this.$emit("editGroup", this.item_group);
      },
      safeNewCategory(category) {
        this.$store
            .dispatch(
                actionTypesMandated.changeCategoryGroup,{groupId:  this.item_group.regGroupId, categoryId: category.id }
            )
            .then(() => {
            })
            .catch((er) => {
              console.log(er, "Error");
            });
      },
      changeStatusPerformance() {
        this.$store
            .dispatch(
                actionTypesMandated.changeStatusPerformanceGroup,
                this.item_group.regGroupId
            )
            .then(() => {
            })
            .catch((er) => {
              console.log(er, "Error");
            });
      },
    }

  }
</script>
