<template>
  <div class="exercises-container">
    <!-- GROUP Exercises -->
    <div class="category" v-if="groupExercises && groupExercises.length">
      <div class="category-header" @click="toggleVisibility('group')">
        <span class="index-text">Количество по категориям в Групповых упражнениях</span>
        <span :class="{'arrow-down': showGroup, 'arrow-right': !showGroup}"><img src="../../assets/svg/arrow-right.svg" alt=""></span>
      </div>
      <ul v-show="showGroup" class="exercise-list">
        <li v-for="(exercise, index) in groupExercises" :key="index">
          <strong>{{ exercise.name }}</strong>
          <p>Количество заявок: {{ exercise.count }}</p>
        </li>
      </ul>
    </div>

    <!-- INDIVIDUAL Exercises -->
    <div class="category" v-if="individualExercises && individualExercises.length">
      <div class="category-header" @click="toggleVisibility('individual')">
        <span class="index-text">Количество по категориям в Индивидуальной программе</span>
        <span :class="{'arrow-down': showIndividual, 'arrow-right': !showIndividual}"><img src="../../assets/svg/arrow-right.svg" alt=""></span>
      </div>
      <ul v-show="showIndividual" class="exercise-list">
        <li v-for="(exercise, index) in individualExercises" :key="index">
          <strong>{{ exercise.name }}</strong>
          <p>Количество заявок: {{ exercise.count }}</p>
        </li>
      </ul>
    </div>

    <!-- DUO Exercises -->
    <div class="category" v-if="duoExercises && duoExercises.length">
      <div class="category-header" @click="toggleVisibility('duo')">
        <span class="index-text">Дуэты</span>
        <span :class="{'arrow-down': showDuo, 'arrow-right': !showDuo}"><img src="../../assets/svg/arrow-right.svg" alt=""></span>
      </div>
      <ul v-show="showDuo" class="exercise-list">
        <li v-for="(exercise, index) in duoExercises" :key="index">
          <strong>{{ exercise.name }}</strong>
          <p>Количество заявок: {{ exercise.count }}</p>
        </li>
      </ul>
    </div>

    <!-- TRIO Exercises -->
    <div class="category" v-if="trioExercises && trioExercises.length">
      <div class="category-header" @click="toggleVisibility('trio')">
        <span class="index-text">Трио</span>
        <span :class="{'arrow-down': showTrio, 'arrow-right': !showTrio}"><img src="../../assets/svg/arrow-right.svg" alt=""></span>
      </div>
      <ul v-show="showTrio" class="exercise-list">
        <li v-for="(exercise, index) in trioExercises" :key="index">
          <strong>{{ exercise.name }}</strong>
          <p>Количество заявок: {{ exercise.count }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showGroup: false,
        showIndividual: false,
        showDuo: false,
        showTrio: false,
      };
    },
    props: {
      app_stat: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      groupExercises() {
        return this.app_stat ? this.app_stat.filter(item => item.typesPrograms === "GROUP") : [];
      },
      individualExercises() {
        return this.app_stat ? this.app_stat.filter(item => item.typesPrograms === "INDIVIDUAL") : [];
      },
      duoExercises() {
        return this.app_stat ? this.app_stat.filter(item => item.typesPrograms === "DUO") : [];
      },
      trioExercises() {
        return this.app_stat ? this.app_stat.filter(item => item.typesPrograms === "TRIO") : [];
      }
    },
    methods: {
      toggleVisibility(type) {
        switch(type) {
          case 'group':
            this.showGroup = !this.showGroup;
            break;
          case 'individual':
            this.showIndividual = !this.showIndividual;
            break;
          case 'duo':
            this.showDuo = !this.showDuo;
            break;
          case 'trio':
            this.showTrio = !this.showTrio;
            break;
        }
      }
    }
  };
</script>

<style scoped>
/* Стили для заголовков категорий */
.category-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
}

.arrow-down, .arrow-right {
  margin-left: 10px;
  transition: transform 0.3s ease;
  display: flex;
  img {
    width: 30px;
    height: 30px;
  }
}

.arrow-down {
  transform: rotate(90deg);
}

.exercise-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.exercise-list li {
  background-color: #fff;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
}
.toggle-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.programs-container {
  margin-top: 20px;
}

.program-category {
  margin-bottom: 30px;
}

.category-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  flex: 1 1 200px;
  padding: 15px;
  background: white;
  box-shadow: 10px 10px 20px #d9d9d9, -10px -10px 20px #ffffff;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-title {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.card-count-container {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.card-count-label {
  color: #666;
}

.card-count {
  font-weight: bold;
  color: #333;
  margin-left: 5px;
}

/* Адаптивность */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .category-header {
    font-size: 16px;
  }

  .exercise-list li {
    font-size: 14px;
  }
}
</style>
