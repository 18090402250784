import { render, staticRenderFns } from "./ItemMandatedInfo.vue?vue&type=template&id=3499fe81&"
import script from "./ItemMandatedInfo.vue?vue&type=script&lang=js&"
export * from "./ItemMandatedInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports