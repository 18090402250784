import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import Login from "../views/Login.vue";
import Profile from "../views/Profile.vue";
import Competition from "../views/NewCompetition.vue";
import Creation from "../views/CreationCompetition.vue";
// import CreateApplication from "../views/CreateApplication.vue";
import DetailedCardCompetition from "../views/DetailedCardCompetition.vue";
import EnteredData from "../views/EnteredData.vue";
import Applications from "../views/Applications.vue";
// import CreateStartList from "../views/CreateStartList";
import Mandated from "../views/Mandated/Mandated";
import ItemMandatedInfo from "../views/Mandated/ItemMandatedInfo";
import NewLogin from '@/views/NewLogin.vue'
import Registration from '@/views/Registration.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import CreateApplicationNew from '@/views/CreateApplicationNew.vue'
//НОВАЯ
import NewCreateStartList from '@/views/NewCreateStartList.vue'
// import CreateStartList from '@/views/CreateStartList.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      Header: true,
    },
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: Login,
  // },
  {
    path: "/login",
    name: "Login",
    component: NewLogin,
    meta: {
      Header: false,
    },
  },
  {
    path: "/registration",
    name: 'Registration',
    component: Registration,
    meta: {
      Header: false,
    },
  },
  {
    path: "/forgot-password",
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      Header: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      Header: true,
    },
  },
  {
    path: "/entered-data",
    name: "EnteredData",
    component: EnteredData,
    meta: {
      Header: true,
    },
  },
  {
    path: "/applications",
    name: "Applications",
    component: Applications,
    meta: {
      Header: true,
    },
  },
  {
    path: "/competition/:id",
    name: "Competition",
    component: DetailedCardCompetition,
    meta: {
      Header: true,
    },
  },

  // {
  //   path: "/competition/:id/new-application",
  //   name: "New-Application",
  //   component: CreateApplication,
  // },
  // {
  //   path: "/competition/:id/edit-application",
  //   name: "Edit-Application",
  //   component: CreateApplication,
  // },
  {
    path: "/competition/:id/new-application",
    name: "New-Application",
    component: CreateApplicationNew,
    meta: {
      Header: true,
    },
  },
  {
    path: "/competition/:id/edit-application",
    name: "Edit-Application",
    component: CreateApplicationNew,
    meta: {
      Header: true,
    },
  },

  // {
  //   path: "/competition/:id/create-start-list",
  //   name: "CreateStartList",
  //   component: CreateStartList,
  // },

  {
    path: "/competition/:id/create-start-list",
    name: "CreateStartList",
    component: NewCreateStartList,
    meta: {
      Header: true,
    },
  },
  // New-Application
  {
    path: "/new-competition",
    name: "New-Competition",
    component: Competition,
    meta: {
      Header: true,
    },
  },
  {
    path: "/new-competition/creation/:id",
    component: Creation,
    name: "Creation",
    props: true,
    meta: {
      Header: true,
    },
  },
  {
    path: "/mandated",
    component: Mandated,
    name: "Mandated",
    meta: {
      Header: true,
    },
  },
  {
    path: "/mandated/:id",
    component: ItemMandatedInfo,
    name: "ItemMandated",
    meta: {
      Header: true,
    },
  },
  // {
  //   path: "/new-competition/creation",
  //   name: "Creation",
  //   component: Creation,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
