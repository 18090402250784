import axios from "@/api/axios";

const getAllInfoMandated = (idCompetition) => {
  return axios.get(`/get/mandate/${idCompetition}`);
};
const getAllSportsmenIndCateg = (info) => {
  return axios.get(
    `/get/mandate/all_sportsmen_by_individual_category/${info.categoryId}/${info.competitionId}`
  );
};
const getAllGroupCateg = (info) => {
  return axios.get(
    `/get/mandate/all_sportsmen_by_group_category/${info.categoryId}/${info.competitionId}`
  );
};
const sortition = (id) => {
  return axios.post(`draw/sportsman?competitionId=${id}`);
};
const changeStatusPerformance = (sportsmenId) => {
  return axios.post(`/admin/change/sportsmen/status/${sportsmenId}`);
}
const changeCategorySportsman = (result) => {
  return axios.post(`/admin/change/sportsmen/category/${result.sportsmenId}/${result.categoryId} `);
}

const editPerformance = (data) => {
  return axios.post(`/admin/change/sportsmen/data-and-coach`, data);
}
//
const editGroupPerformance = (data) => {
  return axios.post(`/admin/change/group-data-coaches`, data);
}

const changeStatusPerformanceGroup =(groupId) => {
  return axios.post(`admin/change/group-status/${groupId} `);
}
const changeCategoryGroup = (result) => {
  return axios.post(`/admin/change/group-category/${result.groupId}/${result.categoryId} `);
}
const addSportsmanToCategory = (data) => {
  return axios.post(`/add/mandate/sportsmen`, data);
}
const addGroupToCategory = (data) => {
  return axios.post(`/mandate/registration-new-group`, data);
}
const addJudgeToCompetition = (data) =>{
  return axios.post(`/mandate/registration-new-judge`, data);
}


const searchSportsmen = (surnameAndName) => {
  return axios.get(`/get/mandate/search/sportsmen/${surnameAndName}`);
}

export default {
  getAllInfoMandated,
  getAllSportsmenIndCateg,
  getAllGroupCateg,
  sortition,
  changeStatusPerformance,
  changeCategorySportsman,
  editPerformance,
  changeStatusPerformanceGroup,
  changeCategoryGroup,
  addSportsmanToCategory,
  addGroupToCategory,
  searchSportsmen,
  editGroupPerformance,
  addJudgeToCompetition
};
