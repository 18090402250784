import axios from "axios";
import { getItem } from "@/helpers/persistanceStorage";
import router from "@/router";
// axios.defaults.baseURL = "http://109.111.185.150:9041/api/1";
axios.defaults.baseURL = "https://reg-gym.ru/api/1";
// axios.defaults.baseURL = "http://80.64.169.167:9041/api/1";
// axios.defaults.baseURL = "https://b1aa-103-45-246-79.ngrok-free.app/api/1";
// axios.defaults.baseURL ="http://192.168.1.160:9041/api/1"

axios.interceptors.response.use(
  (response) => {
    // console.log(response, "response inter");
    if (response.status === 401) {
      alert("You are not authorized");
    }
    return response;
  },
  (error) => {
    console.log(error, "error resp", error.response?.status);
    if (error.response?.status === 401) {
      localStorage.removeItem("accessTokenStoreApplication");
      // document.cookie = `accessToken=""; `;
      localStorage.removeItem("infoUserApplication");
      router.push({ name: "Login" });
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);
// // Функция для получения токена из cookie
// function getAccessToken() {
//   const token = document.cookie
//       .split("; ")
//       .find(row => row.startsWith("accessToken="))
//       ?.split("=")[1];
//   return token;
// }
// // При отправке запросов добавляем токен из cookie в заголовок
// axios.interceptors.request.use(async config => {
//   // Получаем токен из cookie
//   const token = getAccessToken();
//   // Если токен есть и срок его действия не истек, добавляем его в заголовок запроса
//   if (token) {
//     config.headers.Authorization = `${token}`;
//     console.log(config, "request");
//     // Проверяем срок действия токена и обновляем его при необходимости
//     // if (shouldRefreshToken()) {
//     //   await refreshToken(); // Функция для обновления токена
//     // }
//   }
//   console.log(config, "request");
//   return config;
// });


//ЗАПАСНОЙ ВАРИАНТ!!!!! где через локальное хранилище
axios.interceptors.request.use(
  (config) => {
    const token = getItem("accessTokenStoreApplication");
    const authorizationToken = token ? `${token}` : "";
    if (authorizationToken === "") {
      console.log(config);
    } else {
      config.headers.Authorization = authorizationToken;
    }
    console.log(config, "request");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
