import statisticsAPi from "@/api/statistics.js";


const state = {
  isLoadingStatistic: false, //ожидание запроса
  validationErrors: null,
  allStatistics: null,
};

export const mutationTypes = {
  getStatisticsStart: "[information] getStatisticsStart",
  getStatisticsSuccess: "[information] getStatisticsSuccess",
  getStatisticsFailure: "[information] getStatisticsFailure",
};
export const actionTypesStatistics = {
  getStatistics: "[information] getStatistics",

};

const mutations = {
  [mutationTypes.getStatisticsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getStatisticsSuccess](state, info) {
    state.isLoading = false;
    state.allStatistics = info.result;
  },
  [mutationTypes.getStatisticsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypesStatistics.getStatistics](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStatisticsStart);
      statisticsAPi
          .getStatistics(id)
          .then((response) => {
            // console.log(response);
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.getStatisticsSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.getStatisticsFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.getStatisticsFailure, err);
            console.log("ошибка", err);
          });
    });
  },

};
export default {
  state,
  mutations,
  actions,
};
